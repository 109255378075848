<template>
    <modal
      :show="true"
      @close="$emit('close')"
      @save="submit"
      :title="$t('Form Property Parking')"
    >
      <div class="mx-5 pa-5">
      <div class="caption">{{$t('Form Property Parking Available Subtitle')}}</div>
      <v-row>
        <v-col md="5" cols="12">
          <v-select
            @change="availableChanged"
            v-model="form.parking.available"
            :items="parkingOptions.available"
            item-text="text"
            item-value="value"
            :placeholder="`${$t('Please select')}...`"
            persistent-placeholder
            dense
            outlined
            hide-details
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-title class="grey--text">
                  {{$t('Please select')}}...
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col md="4" cols="12" v-if="showParkingOptions">
          <v-select
            v-model="form.parking.space"
            :items="parkingOptions.space"
            item-text="text"
            item-value="value"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col md="3" cols="12" v-if="showParkingOptions">
          <v-select
            v-model="form.parking.type"
            :items="parkingOptions.type"
            item-text="text"
            item-value="value"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="showParkingOptions">
        <v-col cols="12" md="4" v-if="showParkingPricingOptions">
          <div class="caption">{{$t('Price per day')}}</div>
          <div class="d-flex">
            <v-text-field
              v-model.number="form.parking.price"
              :error-messages="firstError('form.parking.price')"
              type="number"
              outlined
              prefix="€"
              :min="1"
              :placeholder="'5'"
              dense
              class="parking-price-right"
              style="width:60%;border-right-radius:0;"
          ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="showParkingOptions" class="caption" :class="{'mt-8': form.parking.available === 'free'}">
        <v-col>{{$t('Form Property Parking Options Subtitle')}}</v-col>
      </v-row>
      <v-row v-if="showParkingOptions">
        <v-col cols="12" sm="6">
            <v-checkbox
              class="mt-0"
              v-model="form.parking.garage"
              :label="$t('parking.garage')"
              hide-details
            ></v-checkbox>
          </v-col>
        <v-col cols="12" sm="6">
            <v-checkbox
              class="mt-0"
              v-model="form.parking.street"
              :label="$t('parking.street')"
              hide-details
            ></v-checkbox>
          </v-col>
        <v-col cols="12" sm="6">
            <v-checkbox
              class="mt-0"
              v-model="form.parking.secure"
              :label="$t('parking.secure')"
              hide-details
            ></v-checkbox>
          </v-col>
        <v-col cols="12" sm="6">
            <v-checkbox
              class="mt-0"
              v-model="form.parking.accessible"
              :label="$t('parking.accessible')"
              hide-details
            ></v-checkbox>
          </v-col>
        <v-col cols="12" sm="6">
            <v-checkbox
              class="mt-0"
              v-model="form.parking.electric_charging"
              :label="$t('parking.electric_charging')"
              hide-details
            ></v-checkbox>
          </v-col>
      </v-row>
      </div>
    </modal>
</template>
<style lang="scss" scoped>
.parking-price-right.v-text-field--outlined fieldset{
  // border:1px solid red !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.parking-price-left.v-text-field--outlined fieldset{
  // border:1px solid red !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left:none;
}
</style>
<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import axios from 'axios';
// import FormWizardMixin from '@/lib/FormWizardMixin';
import FormValidationMixin from '@/lib/FormValidationMixin';
import ParkingOptionsMixin from '@/lib/ParkingOptionsMixin';

export default {
  props: ['parking', 'ad'],
  mixins: [validationMixin, FormValidationMixin, ParkingOptionsMixin], // FormWizardMixin
  validations() {
    const validators = {
      form: {
      },
    };
    if (this.form.parking.available === 'paid') {
      validators.form.parking = {};
      validators.form.parking.price = {
        minValue: minValue(1),
        maxValue: maxValue(500),
        required,
      };
    }
    return validators;
  },
  data() {
    return {
      form: {
        parking: {
          available: null,
          space: 'private',
          type: 'off_site',
          price: 0,
          price_type: 'per_day',

          garage: false,
          secure: false,
          street: false,
          accessible: false,
          electric_charging: false,
        },
      },
      showParkingModal: false,

    };
  },
  mounted() {
    // set form values
    this.form.parking = { ...this.parking };
  },
  computed: {
    ...mapGetters(['loading']),
    showParkingOptions() {
      return this.form.parking.available !== null && this.form.parking.available !== 'no';
    },
    showParkingPricingOptions() {
      return this.form.parking.available === 'paid';
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.dispatch('loading', true);
      this.form.id = this.ad.id;
      axios
        .post('panel/ads/validation/parking', {
          ...this.form,
        })
        .then(() => {
          this.$store.dispatch('message', this.$t('Parking updated'));
          this.$store.dispatch('loading', false);
          this.$emit('update', this.form.parking);
          this.$emit('close');
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    availableChanged() {
      if (this.form.parking.available !== 'paid') {
        this.form.parking.price = 0;
      }
      if (this.form.parking.available === 'no') {
        this.form.parking.price = 0;
        this.form.parking.garage = false;
        this.form.parking.secure = false;
        this.form.parking.street = false;
        this.form.parking.accessible = false;
        this.form.parking.electric_charging = false;
      }
    },
  },
};
</script>

<template>
  <wizzard-container :infos="infos">
    <help-center-link
      topic="create-terms"
      link-text="Uslovi izdavanja"
      mode="info"
    />
    <div class="title">{{$t('Form Terms Title')}}</div>
    <p class="caption">{{$t('Form Terms Subtitle')}}</p>

    <v-checkbox
      v-model="form.terms"
      :value="form.terms"
      :error-messages="firstError('form.terms')"
      hide-details
    >
      <template v-slot:label>
        <div>
          {{link.label}}
          <a :href="link.url" target="_blank" @click.stop>{{link.anchor}}</a>
        </div>
      </template>
    </v-checkbox>

    <form-action-buttons
      @submit="submit"
      @prev="$emit('prev')"
      :status="ad.status" />
  </wizzard-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { sameAs } from 'vuelidate/lib/validators';
import axios from 'axios';
import FormWizardMixin from '@/lib/FormWizardMixin';
import FormValidationMixin from '@/lib/FormValidationMixin';
import HelpCenterLink from '@/components/HelpCenterLink.vue';
import WizzardContainer from './WizzardContainer.vue';

export default {
  mixins: [validationMixin, FormValidationMixin, FormWizardMixin],
  components: {
    WizzardContainer,
    HelpCenterLink,
  },
  validations: {
    form: {
      terms: {
        sameAs: sameAs(() => true),
      },
    },
  },
  data() {
    return {
      form: {
        id: undefined,
        terms: undefined,
      },
      infos: [
        {
          icon: 'green-energy',
          title: 'Form Terms InfoTitle',
          description: 'Form Terms InfoDescription',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['loading']),
    link() {
      if (this.$i18n.locale === 'en') {
        return {
          label: 'I agree with',
          anchor: 'Conditions for renting an accommodation',
          url: `${process.env.VUE_APP_APP_URL}/conditions-for-renting-an-accommodation`,
        };
      }
      return {
        label: 'Slažem se sa',
        anchor: 'Uslovima izdavanja smeštaja',
        url: `${process.env.VUE_APP_APP_URL}/sr/uslovi-izdavanja-smestaja`,
      };
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post('panel/ads/validation/terms', {
          ...this.form,
        })
        .then(() => {
          this.$store.dispatch('message', this.$t('AdForm Next Step Message'));
          this.$store.dispatch('loading', false);
          window.dataLayer.push({ event: 'apartment_wizard_process_completed' });
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
  },
  mounted() {
    if (this.ad && Object.keys(this.ad).length !== 0) {
      this.form.id = this.ad.id;
    }
  },
};
</script>

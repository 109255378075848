<template>
  <wizzard-container :infos="infos">
    <help-center-link
      topic="create-structure"
      link-text="Kako uneti strukturu?"
      mode="info"
    />
    <div class="title">{{$t('Form Property Bedrooms Title')}}</div>
    <v-select
      class="mb-3"
      single-line
      v-model.number="form.bedrooms"
      :items="bedroomOptions"
      item-text="text"
      item-value="value"
      :label="$t('Form Property Bedrooms Label')"
      dense
      outlined
      @change="form.checkin_to = null"
    ></v-select>

    <div class="title">{{$t('Form Property m2 Title')}}</div>
    <v-text-field
        v-model.number="form.m2"
        :error-messages="firstError('form.m2')"
        type="number"
        class="mt-2"
        outlined
        suffix="㎡"
        :label="$t('Form Property m2')"
        :placeholder="'m2'"
        dense
        min="1"
    />

    <div class="title">{{$t('Form Property Bathrooms Title')}}</div>
    <div class="caption">{{$t('Form Property Bathrooms Subtitle')}}</div>
    <number
      v-model.number="form.bathrooms"
      :label="$t('Form Property Bathrooms')"
      class="mb-5 mt-2" />

    <div class="title">{{$t('Form Property Beds Title')}}</div>
    <number
      v-model.number="form.beds"
      :label="$t('Form Property Beds')"
      class="mb-5 mt-2" />

    <div class="title">{{$t('Form Property BedStructure Title')}}</div>
    <div>{{$t('Form Property BedStructure Subtitle')}}</div>

    <modal
      v-if="showBedTypesModal"
      :show="showBedTypesModal"
      @close="editRoomBeds = undefined"
      @save="editRoomBeds = undefined"
      :title="form.room_bed_structure[editRoomBeds].text"
      :subtitle="$t('Form Property BedStructure Modal Subtitle')"
    >
      <div class="justify-center w-100 mt-2 px-2 mx-2 px-sm-10 mx-sm-10">
        <div v-for="bed in form.room_bed_structure[editRoomBeds].beds" :key="bed.id">
          <number
            v-model.number="bed.count"
            :label="bed.name[$i18n.locale]"
            :minimum="0"
            class="mb-6 mt-2 " />
        </div>
      </div>
    </modal>

    <div
      v-for="(room,index) in form.room_bed_structure"
      :key="room.text">
      <v-divider></v-divider>
      <div class='pa-3 py-5'>
        <div class="d-flex align-center justify-space-between">
          <div class="subtitle-2">{{$t(room.text)}}</div>
          <v-btn outlined small @click.stop="editRoomBeds = index">{{$t('Add Beds')}}</v-btn>
        </div>
        <div class="grey--text">
          <div v-for="bed in room.beds" :key="bed.id">
            <template v-if="bed.count">
              <strong>{{bed.count}}</strong> {{bed.name[$i18n.locale]}}
            </template>
          </div>
        </div>
      </div>
    </div>
    <v-divider></v-divider>

    <help-center-link
      topic="create-parking"
      link-text="Kako uneti parking?"
      mode="info"
      class="mt-4"
    />
    <div class="title mt-4">{{$t('Form Property Parking')}}</div>
    <div class="d-flex align-center justify-space-between">
      <div class="">
        <div style="white-space:pre-wrap;">{{parkingString}}</div>
          <v-chip
            v-if="form.parking.garage"
            small class="mr-1" outlined>
            {{ $t('parking.garage') }}
          </v-chip>
          <v-chip
            v-if="form.parking.secure"
            small class="mr-1" outlined>
            {{ $t('parking.secure') }}
          </v-chip>
          <v-chip
            v-if="form.parking.electric_charging"
            small class="mr-1" outlined>
            {{ $t('parking.electric_charging') }}
          </v-chip>
          <v-chip
            v-if="form.parking.street"
            small class="mr-1" outlined>
            {{ $t('parking.street') }}
          </v-chip>
          <v-chip
            v-if="form.parking.accessible"
            small class="mr-1" outlined>
            {{ $t('parking.accessible') }}
          </v-chip>
      </div>
    <v-btn outlined small class="mr-3"
      @click.stop="showParkingModal = true">{{$t('Change')}}</v-btn>
    </div>
    <parking-modal
      :parking="form.parking"
      :ad="ad"
      v-if="showParkingModal"
      @close="showParkingModal = false"
      @update="updateParking($event)"
    />
    <form-action-buttons
      @submit="submit"
      @prev="$emit('prev')"
      :status="ad.status"/>

  </wizzard-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import FormWizardMixin from '@/lib/FormWizardMixin';
import FormValidationMixin from '@/lib/FormValidationMixin';
import ParkingOptionsMixin from '@/lib/ParkingOptionsMixin';
import ApartmentMixin from '@/lib/ApartmentMixin';
import ParkingModal from '@/views/ads/forms/apartment/ParkingModal.vue';
import HelpCenterLink from '@/components/HelpCenterLink.vue';
import WizzardContainer from '../WizzardContainer.vue';

export default {
  mixins: [validationMixin, FormValidationMixin, FormWizardMixin, ApartmentMixin, ParkingOptionsMixin],
  components: {
    WizzardContainer,
    ParkingModal,
    HelpCenterLink,
  },
  validations() {
    return {
      form: {
        bedrooms: {
          required,
        },
        beds: {
          required,
        },
        bathrooms: {
          required,
        },
        m2: {
          required,
        },
      },
    };
  },
  data() {
    return {
      form: {
        id: null,
        bathrooms: 1,
        bedrooms: 0,
        beds: 1,
        m2: 1,
        room_bed_structure: [],
        parking: {
          available: null,
          space: 'private',
          type: 'off_site',
          price: 0,
          price_type: 'per_day',

          garage: false,
          secure: false,
          street: false,
          accessible: false,
          electric_charging: false,
        },
      },
      showParkingModal: false,
      bedTypeOptions: [],
      editRoomBeds: undefined,
      // infos: [
      //   {
      //     icon: 'green-energy',
      //     title: 'Form Property InfoTitle',
      //     description: 'Form Property InfoDescription',
      //   },
      //   {
      //     title: 'Form Property Tutorial Title',
      //     description: '',
      //     youtube: 'https://www.youtube.com/embed/AEp0gOJ3qBU',
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters(['loading']),
    showBedTypesModal() {
      if (this.editRoomBeds !== undefined) {
        return true;
      }
      return false;
    },
    parkingString() {
      if (this.form.parking.available === null || this.form.parking.available === 'no') {
        return this.$t('Form Property No Parking');
      }
      const priceTypeOption = this.parkingOptions.price_type
        .find((o) => o.value === this.form.parking.price_type);
      const availableOption = this.parkingOptions.available
        .find((o) => o.value === this.form.parking.available);
      const spaceOption = this.parkingOptions.space
        .find((o) => o.value === this.form.parking.space);
      const typeOption = this.parkingOptions.type
        .find((o) => o.value === this.form.parking.type);

      return `${availableOption.text}, ${this.form.parking.price}€ ${priceTypeOption.text} - (${spaceOption.text},${typeOption.text})`;
    },
    infos() {
      const infos = [
        {
          icon: 'green-energy',
          title: 'Form Property InfoTitle',
          description: 'Form Property InfoDescription',
        },
      ];
      if (this.$i18n.locale === 'sr') {
        infos.push(
          {
            title: 'Form Property Tutorial Title',
            description: '',
            youtube: 'https://www.youtube.com/embed/AEp0gOJ3qBU',
          },
        );
      }
      return infos;
    },
  },
  watch: {
    'form.bedrooms': function (bedrooms) {
      if (this.form.room_bed_structure.length < bedrooms + 1) {
        // if (!this.ad.detail) { // not inital data
        if (this.form.room_bed_structure.length === 0) {
          this.form.room_bed_structure.push({
            text: this.$t('Living room'),
            beds: JSON.parse(JSON.stringify(this.bedTypeOptions)),
          });
        }
        const diff = bedrooms - this.form.room_bed_structure.length + 1;
        for (let index = 0; index < diff; index += 1) {
          this.form.room_bed_structure.push({
            text: `${this.$t('Bedroom')} ${this.form.room_bed_structure.length}`,
            beds: JSON.parse(JSON.stringify(this.bedTypeOptions)),
          });
        }
        // }
      } else {
        this.form.room_bed_structure = this.form.room_bed_structure.slice(0, bedrooms + 1);
      }
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post('panel/ads/validation/property-and-guest', {
          ...this.form,
        })
        .then(() => {
          // console.log('sacuvano');
          this.$store.dispatch('message', this.$t('AdForm Next Step Message'));
          this.$store.dispatch('loading', false);
          this.$emit('success');
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
          this.scrollToFirstError();
        });
    },
    updateParking(parking) {
      this.form.parking = parking;
    },
  },
  beforeCreate() {
    this.$store.dispatch('loading', true);
    axios
      .get('panel/ads/apartment/bed-types')
      .then(({ data }) => {
        this.$store.dispatch('loading', false);
        const beds = data.map((bed) => ({
          ...bed,
          count: 0,
        }));
        this.bedTypeOptions = beds;
        if (this.form.room_bed_structure.length === 0) {
          this.form.room_bed_structure.push({
            text: this.$t('Living room'),
            beds: JSON.parse(JSON.stringify(this.bedTypeOptions)),
          });
        }
      })
      .catch((error) => {
        this.$store.dispatch('setErrors', error.response.data.errors);
        this.$store.dispatch('loading', false);
      });
  },
  mounted() {
    this.$store.dispatch('loading', true);
    axios
      .get('panel/ads/apartment/bed-types')
      .then(({ data }) => {
        this.$store.dispatch('loading', false);
        const beds = data.map((bed) => ({
          ...bed,
          count: 0,
        }));
        this.bedTypeOptions = beds;

        if (this.ad && this.ad.id) {
          this.form.id = this.ad.id;
        }
        if (this.ad.detail) {
          this.form.bathrooms = this.ad.detail.bathrooms;
          this.form.bedrooms = this.ad.detail.bedrooms;
          this.form.beds = this.ad.detail.beds;
          this.form.m2 = this.ad.detail.m2;
          this.form.parking = this.ad.detail.parking;
          if (Array.isArray(this.ad.detail.room_bed_structure)) {
            this.form.room_bed_structure = this.ad.detail.room_bed_structure;
          }
        }

        if (this.form.room_bed_structure.length === 0) {
          this.form.room_bed_structure.push({
            text: this.$t('Living room'),
            beds: JSON.parse(JSON.stringify(this.bedTypeOptions)),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch('setErrors', error.response.data.errors);
        this.$store.dispatch('loading', false);
      });
  },
};
</script>

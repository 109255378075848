<template>
  <wizzard-container :infos="infos">
    <help-center-link
      topic="create-gallery"
      link-text="Kako dodati fotografije?"
      mode="info"
    />
    <div class="title">{{$t('Form Gallery Title')}}</div>
    <p class="caption">{{ formGallerySubtitle }}</p>

    <media-library-collection
      v-if="galleryLoaded"
      name="collection"
      @change="onChangeCollection"
      :validation-rules="{
        accept: ['image/png', 'image/jpeg'],
        maxSizeInKB: 1024*5,
        minSizeInKB: 50
      }"
      :max-items="20"
      :validation-errors="collectionErrors"
      :initial-value="gallery"
      @is-ready-to-submit-change="isReadyToSubmit = !$event"
      :upload-domain="uploadDomain"
      :translations="translations"
    />
    <form-action-buttons
      :loading="loadingBtn"
      @submit="submit"
      @prev="$emit('prev')"
      :status="ad.status" />
  </wizzard-container>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import MediaLibraryMixin from '@/lib/MediaLibraryMixin';
import FormWizardMixin from '@/lib/FormWizardMixin';
import HelpCenterLink from '@/components/HelpCenterLink.vue';
import WizzardContainer from './WizzardContainer.vue';

export default {
  mixins: [MediaLibraryMixin, FormWizardMixin],
  components: {
    WizzardContainer,
    HelpCenterLink,
  },
  data() {
    return {
      form: {
        status: undefined,
      },
      gallery: undefined,

      collection: undefined,
      collectionErrors: {},

      isReadyToSubmit: false,

      galleryLoaded: false,
    };
  },
  computed: {
    ...mapGetters(['loading']),
    formGallerySubtitle() {
      if (this.category && this.category.const) {
        return this.$t(`Form Gallery Subtitle ${this.category.const}`);
      }
      return this.$t('Form Gallery Subtitle apartment');
    },
    formGalleryInfoCardDescription() {
      if (this.category && this.category.const) {
        return this.$t(`Form Gallery InfoDescription ${this.category.const}`);
      }
      return this.$t('Form Gallery InfoDescription apartment');
    },
    infos() {
      const infos = [
        {
          icon: 'green-energy',
          title: 'Form Gallery InfoTitle',
          description: this.formGalleryInfoCardDescription,
        },
      ];
      if (this.$i18n.locale === 'sr') {
        infos.push(
          {
            title: 'Form Gallery Tutorial Title',
            description: '',
            youtube: 'https://www.youtube.com/embed/X_DCOplvBSk',
          },
        );
      }
      return infos;
    },
    loadingBtn() {
      return this.isReadyToSubmit || this.loading;
    },
  },
  methods: {
    onChangeCollection(media) {
      this.gallery = media;
    },
    submit() {
      this.$store.dispatch('loading', true);
      axios
        .post('/panel/ads/validation/gallery', {
          media: this.gallery,
          id: this.ad.id,
          status_next: this.statusNext,
        })
        .then(() => {
          this.$store.dispatch('message', this.$t('AdForm Next Step Message'));
          this.$store.dispatch('loading', false);
          this.$emit('success');
        })
        .catch((error) => {
          this.$store.dispatch('loading', false);
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.scrollToFirstError();
        });
    },
  },
  mounted() {
    this.$store.dispatch('loading', true);
    axios
      .get(`panel/ads/gallery/${this.ad.id}`)
      .then(({ data }) => {
        this.$store.dispatch('loading', false);
        this.gallery = data;
        this.galleryLoaded = true;
      })
      .catch((error) => {
        this.$store.dispatch('setErrors', error.response.data.errors);
        this.$store.dispatch('loading', false);
        this.galleryLoaded = true;
      });
  },
};
</script>

export default {
  props: {
    ad: {
      required: true,
      type: Object,
    },
    category: {
      required: true,
      type: Object,
    },
    statusNext: {
      required: true,
    },
  },
  mounted() {
    if (this.statusNext) {
      console.warn(`${this.statusNext}  - (status_next) mounted form WIZARD MIXIN`);
      this.form.status_next = this.statusNext;
    }
  },
  methods: {
    scrollToFirstError() {
      // next tick is used because otherwise vuetify adds error messages after this triggers
      this.$nextTick(() => {
        const element = document.querySelector('.error--text') || document.querySelector('.container');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    },
  },
};

<template>
  <main-layout
    v-if="category"
    :title="!this.ad.id
      ? `${$t('Create')} ${category.singular[$i18n.locale] === 'Atrakcija' ? 'Atrakciju' : category.singular[$i18n.locale]}`
      : ad.name
    "
    :previewUrl="previewUrl"
    :adStatus="adStatus"
    :ad="ad"
    @requestApproval="requestApproval">

    <template v-slot:body>

      <v-stepper v-model="stepper" class=" overflow-visible">

        <v-stepper-header >
          <template v-if="!adWizzardCompleted">
              <template
                v-for="(step,index) in adFormComponents">
                <v-stepper-step
                  :key="'step_'+index"
                  :step="index"
                  :complete="stepper > index"
                  >
                  {{ $t(`AdFormStatus.${step.status}`) }}
                </v-stepper-step>
              </template>
          </template>
          <template v-else>
            <v-tabs v-model="stepper" class="sub-tabs" show-arrows>
              <v-tab
                v-for="(step,index) in adFormComponents"
                :key="'tab_'+index"
                @click="tabClick(index)">
                {{ $t(`AdFormStatus.${step.status}`) }}
              </v-tab>
            </v-tabs>
            <v-divider class="ma-0"></v-divider>
          </template>

        </v-stepper-header>

        <!-- <v-stepper-items>
          <v-stepper-content
            v-for="(step,index) in adFormComponents"
            :key="'step_content_'+index"
            :step="index">
              <component
                v-if="stepper == index"
                v-bind:is="step.component"
                :ad="ad"
                :category="category"
                :status-next="statusNext"
                @success="success"
                @prev="prev"
                :key="'component_'+index">
              </component>
          </v-stepper-content>
        </v-stepper-items> -->

        <component
          class="mt-md-2 pb-16 mb-2"
          v-if="adFormComponents[stepper]"
          v-bind:is="adFormComponents[stepper].component"
          :ad="ad"
          :category="category"
          :status-next="statusNext"
          @success="success"
          @prev="prev">
        </component>

      </v-stepper>

    <v-dialog
      v-model="thankYouDialog"
      max-width="400"
      id="popup-111">
      <v-card>
        <v-card-title class="headline">{{$t('AdForm Successful Ad Submit Message Title')}}!</v-card-title>
        <v-card-text>{{$t('AdForm Successful Ad Submit Message Description')}}!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="thankYouDialog = false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="approveAgainDialog"
      max-width="400">
      <v-card>
        <v-card-title class="headline">{{$t('AdForm Successful Ad Re-Submit Message Title')}}</v-card-title>
        <v-card-text>{{$t('AdForm Successful Ad Re-Submit Message Description')}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="approveAgainDialog = false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
  </main-layout>
</template>
<style lang="scss" scoped>
  .v-stepper.theme--light{
    box-shadow: none;
  }
  .v-stepper__header{
    box-shadow: none;
  }
  .v-stepper__step{
    padding:5px;
  }
</style>
<style lang="scss">
  // WE DO NOT USE THIS // ToDo delete
  .dot-no-color .v-stepper__step__step{
    color: transparent !important;
    height: 16px;
    min-width: 16px;
    width: 16px;
  }
  .sub-tabs.v-tabs > .v-tabs-bar .v-tab{
    text-transform: none;
  }
  .sub-tabs.v-tabs .v-tabs-bar{
    border-bottom: solid 1px #DDDDDD !important;
  }
  .v-card__title.headline{
    white-space:pre-wrap;
    word-break:break-word
  }
</style>
<script>
import MetaTitleMixin from '@/lib/MetaTitleMixin';
import ApartmentMixin from '@/lib/ApartmentMixin';
import axios from 'axios';
import { mapGetters } from 'vuex';
import AdFormInfo from './forms/FormInfo.vue';
import AdFormLocation from './forms/FormLocation.vue';
import AdFormTags from './forms/FormTags.vue';
import AdFormGallery from './forms/FormGallery.vue';
import AdFormTerms from './forms/FormTerms.vue';
import AdActivityLog from './forms/ActivityLog.vue';
import AdFormApartmentPricing from './forms/apartment/FormApartmentPricing.vue';
import AdFormApartmentReservations from './forms/apartment/FormApartmentReservations.vue';
import AdFormApartmentPropertyAndGuest from './forms/apartment/FormApartmentPropertyAndGuest.vue';

export default {
  mixins: [MetaTitleMixin, ApartmentMixin],
  props: {
    categoryConst: {
      required: false,
      type: String,
    },
    id: {
      required: false,
      type: String,
    },
    stepSlug: {
      required: false,
      type: String,
    },
  },
  components: {
    AdFormInfo,
    AdFormLocation,
    AdFormGallery,
    AdFormTags,
    AdFormTerms,
    AdActivityLog,
  },
  data() {
    return {
      ad: {},
      category: undefined,
      stepper: 0,
      form: {
        name: 'Ivan', // ToDo ?? remove this
      },
      thankYouDialog: false,
      approveAgainDialog: false,
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'user']),
    adStatus() {
      if (!this.ad.id && !this.ad.status) {
        return undefined;
      }
      if (this.ad.id && Boolean(Number(this.ad.active[this.$i18n.locale])) && this.ad.status === 'listed') {
        return 'active';
      }
      return this.ad.status;
    },
    previewUrl() {
      return this.getAdSinglePagePreviewUrl(this.ad);
    },
    totalSteps() {
      return this.adFormComponents.length - 1;
    },
    statusNext() {
      if (this.ad.status && ['pending', 'pending_fix', 'listed', 'unlisted'].includes(this.ad.status)) {
        return undefined;
      }
      if (!this.adFormComponents[this.stepper + 1]) {
        return 'pending';
      }
      return this.adFormComponents[this.stepper + 1].status;
    },
    adFormComponents() {
      let form = [
        {
          component: AdFormInfo,
          name: 'Detalji',
          status: 'info',
        },
        {
          component: AdFormLocation,
          name: 'Lokacija',
          status: 'location',
        },
        {
          component: AdFormTags,
          name: 'Opremljenost',
          status: 'tags',
        },
        {
          component: AdFormGallery,
          name: this.$t('Photos'),
          status: 'gallery',
        },
      ];

      if (this.category && this.category.const === 'apartment') {
        form = [
          {
            component: AdFormInfo,
            name: 'Detalji',
            status: 'info',
          },
          {
            component: AdFormApartmentPropertyAndGuest,
            name: 'Objekat i Gosti',
            status: 'property',
          },
          {
            component: AdFormLocation,
            name: 'Lokacija',
            status: 'location',
          },
          {
            component: AdFormTags,
            name: 'Opremljenost',
            status: 'tags',
          },
          {
            component: AdFormGallery,
            name: 'Fotografije',
            status: 'gallery',
          },
          {
            component: AdFormApartmentPricing,
            name: 'Cena',
            status: 'pricing',
          },
          {
            component: AdFormApartmentReservations,
            name: 'Rezervacije',
            status: 'reservation',
          },
        ];
      }

      // Add terms
      if (!this.adWizzardCompleted) {
        form.push({
          component: AdFormTerms,
          name: 'Uslovi',
          status: 'terms',
        });
        // form.push({
        //   component: AdFormThanks,
        //   name: 'Thank you',
        //   status: undefined,
        // });
      }
      if (this.adWizzardCompleted) {
        form.push({
          component: AdActivityLog,
          name: 'ActivityLog',
          status: 'activity',
        });
      }
      return form;
    },
    adWizzardCompleted() {
      if (!this.ad.id || ['info', 'property', 'location', 'tags', 'gallery', 'pricing', 'reservation', 'terms'].includes(this.ad.status)) {
        return false;
      }
      return true;
    },
  },
  watch: {
    $route() {
      if (this.id) {
        this.getAd();
      }
    },
    id() {
      this.subscribe();
    },
  },
  mounted() {
    if (this.id) {
      this.subscribe();
      this.getAd();
    } else {
      // show create form
      this.$store
        .dispatch('getAdCategoryByConst', this.categoryConst)
        .then((category) => {
          this.category = category;
          this.setMetaTitle();
          this.$store.dispatch('loading', false);
        });
    }
  },
  methods: {
    subscribe() {
      this.$echo.private(`ad.${this.id}`)
        .listen('.App\\Panel\\Events\\AdUpdated', (e) => {
          this.ad = e.ad;
        })
        .listen('.App\\Panel\\Events\\AdSubmited', () => {
          this.stepper = 0;
          this.thankYouDialog = true;
        })
        .listen('.App\\Panel\\Events\\AdSubmitedAgain', () => {
          this.approveAgainDialog = true;
        });
    },
    success() {
      this.$store.dispatch('clearErrors');
      if (!this.adWizzardCompleted) {
        this.next();
      }
    },
    next() {
      this.stepper += 1;
      document.querySelector('h1').scrollIntoView();
      document.documentElement.scrollTo({ top: 0 });
    },
    prev() {
      this.$store.dispatch('clearErrors');
      if (this.stepper > 0) {
        this.stepper -= 1;
        document.querySelector('h1').scrollIntoView();
        document.documentElement.scrollTo({ top: 0 });
      }
    },
    getAd() {
      this.$store
        .dispatch('getAd', this.id)
        .then((ad) => {
          if (
            this.user
            && ad.owner_id !== this.user.id
            && !this.isAdmin
          ) {
            this.$store.dispatch('loading', false);
            this.$router.push({ name: 'Dashboard' });
            return;
          }
          this.category = ad.category;
          this.ad = ad;
          this.setMetaTitle();
          if (this.ad.status) {
            // load current tab
            const componentIndex = this.adFormComponents
              .findIndex((c) => c.status === this.ad.status);

            if (componentIndex >= 0) {
              this.stepper = componentIndex;
            }

            // If step slug is in url load that component.
            if (this.stepSlug) {
              const index = Object.values(this.adFormComponents).findIndex((item) => item.status === this.stepSlug);
              if (index) {
                this.stepper = index;
              }
            }
          }
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.message);
          this.$store.dispatch('loading', false);
        });
    },
    setMetaTitle() {
      this.$title = this.id
        ? `${this.$t('Edit')} ${this.category.singular[this.$i18n.locale]} ${this.ad.name}`
        : `${this.$t('Create')} ${this.category.singular[this.$i18n.locale]}`;
    },
    requestApproval() {
      this.isLoading = true;
      this.$store.dispatch('loading', true);
      axios
        .post(`/panel/ads/pending_fix-to-pending/${this.ad.id}`, {
          ...this.form,
        })
        .then(() => {
          this.$store.dispatch('message', 'Apartman poslat na odobravanje');
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    tabClick(index) {
      this.stepper = index;

      const component = this.adFormComponents[index];

      if (component.status) {
        // add component "status" (slug) to url
        window.history.replaceState({}, null, `/listing/${this.categoryConst}/${this.id}/${component.status}`);
      }
    },
  },
};
</script>

<template>
  <wizzard-container full-width>
    <help-center-link
      topic="create-tags"
      link-text="Kako uneti opremljenost?"
      mode="info"
    />
    <v-alert
        v-if="$v.form.tags.$error"
        border="left"
        colored-border
        type="error"
        class="box-sha"
      >
      {{$t('You must select at lest one tag')}}
    </v-alert>
    <div
      v-for="tagGroup in tagGroups"
      :key="tagGroup.id"
      class="mb-10">
      <div class="title">{{tagGroup.name[$i18n.locale]}}</div>
      <p class="caption mb-0">{{tagGroup.description_panel[$i18n.locale]}}</p>
      <v-row>
          <v-col
            v-for="(tag,index) in tagGroup.tags"
            :key="index"
            cols="12"
            sm="3"
            md="3"
          >
            <v-checkbox
              v-model="form.tags"
              :label="tag.name[$i18n.locale]"
              :value="tag.id"
              hide-details
            ></v-checkbox>
          </v-col>
      </v-row>
    </div>

    <form-action-buttons
      @submit="submit"
      @prev="$emit('prev')"
      :status="ad.status" />

  </wizzard-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import FormWizardMixin from '@/lib/FormWizardMixin';
import FormValidationMixin from '@/lib/FormValidationMixin';
import HelpCenterLink from '@/components/HelpCenterLink.vue';
import WizzardContainer from './WizzardContainer.vue';

export default {
  mixins: [validationMixin, FormValidationMixin, FormWizardMixin],
  components: {
    WizzardContainer,
    HelpCenterLink,
  },
  validations: {
    form: {
      tags: {
        required,
      },
    },
  },
  data() {
    return {
      form: {
        id: null,
        tags: [],
      },

      tagGroups: [],
    };
  },
  computed: {
    ...mapGetters(['loading']),
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post('panel/ads/validation/tags', {
          ...this.form,
        })
        .then(() => {
          this.$store.dispatch('message', this.$t('AdForm Next Step Message'));
          this.$store.dispatch('loading', false);
          this.$emit('success');
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
          this.scrollToFirstError();
        });
    },
  },
  mounted() {
    if (this.ad && this.ad && Object.keys(this.ad).length !== 0) {
      this.form.id = this.ad.id;
      this.form.tags = this.ad.tagIds;
    }
    this.$store
      .dispatch('getCategoryTags', this.ad.category_id)
      .then((tagGroups) => {
        this.tagGroups = tagGroups;
      });
  },
};
</script>

<template>
  <v-row>
    <!-- <v-col
      cols="12"
      :md="fullWidth ? 8 : 4"
      offset-md="2"
      order-sm="2"
      order-md="1" >
        <slot></slot>
    </v-col> -->
    <v-col
      cols="12"
      :lg="fullWidth ? 10 : 5"
      :md="fullWidth ? 10 : 6"
      offset-lg="1"
    >
        <slot></slot>
    </v-col>
    <v-col
      v-if="infos && infos.length"
      cols="12"
      md="6"
    >
      <slot name="right-sidebar"></slot>

      <info
        v-for="(info, index) in infos" :key="index"
        class="mb-10"
        :title="info.title"
        :description="info.description"
        :icon="info.icon"
        :youtube="info.youtube"
      />
    </v-col>
  </v-row>
</template>
<script>
import Info from '@/components/Info.vue';

export default {
  components: {
    Info,
  },
  props: {
    infos: {
      required: false,
      type: Array,
    },
    fullWidth: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>

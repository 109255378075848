<template>
  <wizzard-container :infos="infos">

      <template #right-sidebar>
        <v-card class="mb-5" outlined :max-width="$vuetify.breakpoint.mdAndUp ? 344 : ''">
          <v-card-text>
            <div class="title my-2">{{$t('Form Pricing Price Title')}}</div>
            <div class="d-flex justify-space-between align-center">
              <span>{{ $t('Pricing Preview Title') }}</span>
              <v-btn
                @click="openPricingModal"
                class="text-capitalize text-body-1 text-decoration-underline black--text px-1"
                text
              >
                {{ $t('Show') }}
              </v-btn>
              <pricing-preview
                v-if="showPricingModal"
                :overwrite="overwritePriceForPricingCalculator"
                :key="pricingCalculatorKey"
                :show="showPricingModal"
                :start="pricingPreviewDates.start"
                :end="pricingPreviewDates.end"
                :allow-change-dates="true"
                :id="String(ad.id)"
                @updateStartEnd="updateStartEnd"
                @close="showPricingModal = false"
              />
              <v-dialog
                v-model="showPricingAlert"
                max-width="400"
                id="popup-111">
                <v-card>
                  <v-card-title class="headline">{{$t('pricing_preview_alert_title')}}</v-card-title>
                  <v-card-text>{{$t('pricing_preview_alert_description')}}</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="showPricingAlert = false"
                    >
                      {{$t('Back')}}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-card-text>
        </v-card>
      </template>

      <div class="mt-5">
        <div class="title">{{$t('Form Property Guests Title')}}</div>
        <!-- <p class="caption">{{$t('Form Property Guests Subtitle')}}</p> -->
        <number
          v-model.number="form.guests"
          :label="$t('Form Property Guests')"
          :max="50"
          class="mb-6 mt-2"
          @input="openExtraGuestChangeConfirmModal"
        />
        <modal
          :show="showExtraGuestsChangeConfirm"
          @close="closeExtraGuestChangeConfirmModal"
          @save="guestChanged"
          save-text="Confirm"
          :title="$t('ExtraGuestChangeConfirm.title')"
          :description="$t('ExtraGuestChangeConfirm.description')"
          hide-top-close-button
          max-width="400"
        />
      </div>

      <v-divider class="mt-5 mb-5"/>

      <help-center-link
        topic="create-price"
        link-text="Kako uneti cenu?"
        mode="info"
      />
      <div class="title">{{$t('Form Pricing Price Placeholder')}}</div>
      <v-text-field
          v-model.number="form.price"
          @keypress="allowOnlyWholeInputNumbers"
          :error-messages="firstError('form.price')"
          type="number"
          class="mt-2"
          outlined
          prefix="€"
          :label="$t('Form Pricing Price')"
          :placeholder="$t('Form Pricing Price Placeholder')"
          :hint="`${$t('Form Pricing Price Hint')}`"
          persistent-hint
          dense
          min="1"
      />

      <v-divider class="mt-5 mb-5"/>

      <div class="title">{{$t('Form Pricing PriceWeekend Placeholder')}}</div>
      <v-text-field
          v-model.number="form.price_weekend"
          @keypress="allowOnlyWholeInputNumbers"
          :error-messages="firstError('form.price_weekend')"
          type="number"
          class="mt-2"
          outlined
          prefix="€"
          :label="$t('Form Pricing PriceWeekend')"
          :placeholder="$t('Form Pricing PriceWeekend')"
          :hint="`${$t('Form Pricing PriceWeekend Hint')}`"
          persistent-hint
          dense
          min="1"
      />

      <v-divider class="mt-5 mb-5"/>

      <template v-if="!showExtraGuestForm">
        <div class="title mt-5">Naknada za dodatnog gosta</div>
          <div v-if="form.guests > 1" class="d-flex align-center justify-space-between">
            <div v-html="extraGuestsString"></div>
            <v-btn

              outlined small class="mr-3"
              @click.stop="showExtraGuestForm = true; form.charge_after_guest = 1;"
            >{{$t('Add')}}</v-btn>
          </div>
          <div v-else>
            {{$t('extra_guest_fee_minimum_guests_required')}}
          </div>
      </template>
      <div v-else >
          <div class="title mb-2">
            {{ $t('Extra guest fee') }}
          </div>
          <v-text-field
              v-if="form.charge_after_guest > 0"
              v-model.number="form.price_per_guest"
              @keypress="allowOnlyWholeInputNumbers"
              :error-messages="firstError('form.price_per_guest')"
              type="number"
              class="mt-2"
              outlined
              prefix="€"
              :label="$t('Form Pricing PricePerGuest')"
              :placeholder="'5'"
              dense
              min="0"
              :hint="`${$t('Form Pricing ChangeAfterGuest')} ${form.charge_after_guest},
              ${$t('Form Pricing PricePerGuestsIs')} ${form.price_per_guest ? form.price_per_guest : '___'}€`"
              persistent-hint
          />
          <number
            v-model.number="form.charge_after_guest"
            :label="$t('Form Pricing ChangeAfterGuest')"
            :max="maxGuests"
            :minimum="1"
            class="mb-5 mt-2" />

          <v-btn
            @click="removeExtraGuestFee"
            small
            text
            class=" text-body-1  black--text px-1 text-transform-none"
          >
            <v-icon>mdi-minus-circle-outline</v-icon>
            <span class="text-decoration-underline ml-1">
              {{ $t('Remove extra guest fee') }}
            </span>
          </v-btn>
      </div>

      <v-divider class="mt-5"/>

      <div class="title mt-5">{{$t('Form Pricing WeeklyDiscount Title')}}</div>
      <v-text-field
          v-model.number="form.weekly_discount"
          @keypress="allowOnlyWholeInputNumbers"
          :error-messages="firstError('form.weekly_discount')"
          type="number"
          class="mt-2"
          outlined
          prefix="%"
          :label="$t('Form Pricing WeeklyDiscount')"
          :placeholder="'0%'"
          dense
          min="0"
          max="100"
      />
      <v-text-field
          v-model.number="form.monthly_discount"
          @keypress="allowOnlyWholeInputNumbers"
          :error-messages="firstError('form.monthly_discount')"
          type="number"
          class="mt-2"
          outlined
          prefix="%"
          :label="$t('Form Pricing MonthlyDiscount')"
          :placeholder="'0%'"
          dense
          min="0"
          max="100"
      />

      <form-action-buttons
        @submit="submit"
        @prev="$emit('prev')"
        :status="ad.status"/>
  </wizzard-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required,
  minValue,
  maxValue,
  between,
} from 'vuelidate/lib/validators';
import axios from 'axios';
import FormWizardMixin from '@/lib/FormWizardMixin';
import FormValidationMixin from '@/lib/FormValidationMixin';
import PricingPreview from '@/components/PricingPreview.vue';
import {
  DATE_FORMAT,
  allowOnlyWholeInputNumbers,
} from '@/components/calendar/config';
import moment from 'moment';
import HelpCenterLink from '@/components/HelpCenterLink.vue';
import WizzardContainer from '../WizzardContainer.vue';

export default {
  mixins: [validationMixin, FormValidationMixin, FormWizardMixin],
  components: {
    WizzardContainer,
    PricingPreview,
    HelpCenterLink,
  },
  validations() {
    return {
      form: {
        guests: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(50),
        },
        price: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(99999),
        },
        price_weekend: {
          minValue: minValue(1),
          maxValue: maxValue(99999),
        },
        charge_after_guest: {
          required,
          minValue: minValue(0),
          maxValue: maxValue(100),
        },
        price_per_guest: {
          required,
          between: between(
            this.form.charge_after_guest === 0 ? 0 : 5,
            255,
          ),
        },
        weekly_discount: {
          required,
          minValue: minValue(0),
          maxValue: maxValue(99),
        },
        monthly_discount: {
          required,
          minValue: minValue(0),
          maxValue: maxValue(99),
        },
      },
    };
  },
  data() {
    return {
      form: {
        id: null,
        guests: 1,
        price: undefined,
        price_weekend: undefined,
        weekly_discount: 0,
        monthly_discount: 0,
        price_per_guest: 0,
        charge_after_guest: 0,
      },
      showPricingModal: false,
      showPricingAlert: false,
      showExtraGuestsChangeConfirm: false,
      pricingPreviewDates: {
        start: moment().format(DATE_FORMAT),
        end: moment().add(1, 'days').format(DATE_FORMAT),
      },
      showExtraGuestForm: false,
    };
  },
  mounted() {
    if (this.ad && this.ad.id) {
      this.form.id = this.ad.id;
    }
    if (this.ad.detail) {
      this.form.guests = this.ad.detail.guests;
      this.form.price = this.ad.detail.price;
      this.form.price_weekend = this.ad.detail.price_weekend;
      this.form.weekly_discount = this.ad.detail.weekly_discount;
      this.form.monthly_discount = this.ad.detail.monthly_discount;
      this.form.price_per_guest = this.ad.detail.price_per_guest;
      this.form.charge_after_guest = this.ad.detail.charge_after_guest;
    }

    if (this.form.charge_after_guest > 0) {
      this.showExtraGuestForm = true;
    }
  },
  computed: {
    ...mapGetters(['loading']),
    maxGuests() {
      return this.form.guests - 1;
    },
    extraGuestsString() {
      if (this.form.charge_after_guest === 0) {
        return this.$t('Not set');
      }
      return `${this.$t('Form Pricing ChangeAfterGuest')} <b>${this.form.charge_after_guest}</b>, ${this.$t('Form Pricing PricePerGuestsIs')} ${this.form.price_per_guest ? `<b>${this.form.price_per_guest}€</b>` : '___'}`;
    },
    infos() {
      const infos = [
        {
          icon: 'green-energy',
          title: 'Form Pricing InfoTitle',
          description: 'Form Pricing InfoDescription',
        },
      ];
      if (this.$i18n.locale === 'sr') {
        infos.push(
          {
            title: 'Form Pricing Tutorial Title',
            description: '',
            youtube: 'https://www.youtube.com/embed/TRa5NtUZYQU',
          },
        );
      }
      return infos;
    },
    overwritePriceForPricingCalculator() {
      return {
        guests: this.form.guests,
        price: this.form.price,
        price_weekend: this.form.price_weekend,
        weekly_discount: this.form.weekly_discount,
        monthly_discount: this.form.monthly_discount,
        price_per_guest: this.form.price_per_guest,
        charge_after_guest: this.form.charge_after_guest,
      };
    },
    pricingCalculatorKey() {
      return `${this.form.guests}${this.form.price}${this.form.price_weekend}${this.form.weekly_discount}${this.form.monthly_discount}${this.form.price_per_guest}${this.form.charge_after_guest}`;
    },
  },
  methods: {
    removeExtraGuestFee() {
      this.form.charge_after_guest = 0;
      this.form.price_per_guest = 0;
      this.showExtraGuestForm = false;
    },
    guestChanged() {
      if (this.form.charge_after_guest >= this.form.guests) {
        this.showExtraGuestsChangeConfirm = false;
        this.form.charge_after_guest = this.form.guests - 1;
        if (this.form.guests - 1 === 0) {
          this.form.price_per_guest = 0;
          this.showExtraGuestForm = false;
        }
      }
    },
    openExtraGuestChangeConfirmModal() {
      if (this.form.charge_after_guest >= this.form.guests) {
        this.showExtraGuestsChangeConfirm = true;
      }
    },
    closeExtraGuestChangeConfirmModal() {
      this.showExtraGuestsChangeConfirm = false;
      this.form.guests += 1;
    },
    allowOnlyWholeInputNumbers,
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.scrollToFirstError();
        // alert(this.collectErrorMessages());
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post('panel/ads/validation/pricing', {
          ...this.form,
        })
        .then(() => {
          this.$store.dispatch('message', this.$t('AdForm Next Step Message'));
          this.$store.dispatch('loading', false);
          this.$emit('success');
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
          this.scrollToFirstError();
        });
    },
    openPricingModal() {
      if (!this.form.price) {
        this.showPricingAlert = true;
      } else {
        this.showPricingModal = true;
      }
    },
    updateStartEnd(data) {
      if (data.start && data.end) {
        // console.log('updateStartEnd in FormApartment Pricing', data);
        this.pricingPreviewDates.start = data.start;
        this.pricingPreviewDates.end = data.end;
      }
    },
  },
};
</script>

<template>
  <v-card outlined class="my-3" :loading="loading">
    <v-card-title dense>{{$t('Import Calendar Title')}}</v-card-title>
    <v-card-text class="pb-0">
      <p>{{$t('Import Calendar Subtitle')}}</p>
      <div class="black--text">{{$t('Import Calendar URL')}}</div>
      <v-text-field
          v-model="form.url"
          :error-messages="firstError('form.url')"
          class="mt-2"
          outlined
          :placeholder="$t('Import Calendar URL Placeholder')"
          dense
      />
      <div class="black--text">{{$t('Import Calendar Name')}}</div>
      <v-text-field
          v-model="form.name"
          :error-messages="firstError('form.name')"
          class="mt-2"
          outlined
          :placeholder="$t('Import Calendar Name Placeholder')"
          dense
      />
    </v-card-text>
    <v-card-actions class="pl-4 pb-4 pt-0">
      <v-btn
        :loading="loading"
        color="primary"
        outlined
        @click="submit"
      >
        {{$t('Import Calendar')}}
      </v-btn>
      <v-btn
        text
        @click="$emit('close')"
      >
        {{$t('Cancel')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import FormValidationMixin from '@/lib/FormValidationMixin';

export default {
  props: {
    ad: {
      required: true,
      type: Object,
    },
  },
  mixins: [validationMixin, FormValidationMixin],
  validations: {
    form: {
      url: {
        required,
      },
      name: {
        required,
      },
    },
  },
  data() {
    return {
      form: {
        url: null,
        name: null,
      },
      loading: false,
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios
        .post('panel/ads/validation/import-calendar', {
          id: this.ad.id,
          ...this.form,
        })
        .then(() => {
          this.$store.dispatch('message', this.$t('Calendar imported'));
          this.$emit('success');
          this.form.url = null;
          this.form.name = null;
          this.loading = false;
          this.$v.$reset();
          this.$emit('close');
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.loading = false;
        });
    },
  },
};
</script>

export default {
  data() {
    return {
      parkingOptions: {
        available: [
          {
            value: 'no',
            text: this.$t('parking.available.no'),
          },
          {
            value: 'paid',
            text: this.$t('parking.available.paid'),
          },
          {
            value: 'free',
            text: this.$t('parking.available.free'),
          },
        ],
        space: [
          {
            value: 'private',
            text: this.$t('parking.space.private'),
          },
          {
            value: 'public',
            text: this.$t('parking.space.public'),
          },
        ],
        type: [
          {
            value: 'off_site',
            text: this.$t('parking.type.off_site'),
          },
          {
            value: 'on_site',
            text: this.$t('parking.type.on_site'),
          },
        ],
        price_type: [
          {
            value: 'per_day',
            text: this.$t('parking.price_type.per_day'),
          },
        ],
      },
    };
  },
};
